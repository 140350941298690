import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import Menu from "./service/Menu";

const routes = [
    
    {
        path: '/dashboard',
        name: 'app',
        component: App,
        children: [
            {
                path: '/dashboard',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/userProfile',
                name: 'userProfile',
                component: () => import('./pages/UserProfile.vue'),
                
            },
            {
                path: '/profile',
                meta: {
                    authRequires: true
                },
                name: 'profile',
                component: () => import('./pages/profile.vue')
            },
            {
                path: '/usermaster',
                meta: {
                    authRequires: true
                },
                name: 'usermaster',
                component: () => import('./pages/UserMaster.vue')
            },
            {
                path: '/clientmaster',
                meta: {
                    authRequires: true
                },
                name: 'clientmaster',
                component: () => import('./pages/ClientMaster.vue')
            },
            {
                path: '/ClientList',
                meta: {
                    authRequires: true
                },
                name: 'ClientList',
                component: () => import('./pages/ClientList.vue')
            },
            {
                path: '/Source',
                meta: {
                    authRequires: true
                },
                name: 'Source',
                component: () => import('./pages/Source.vue')
            },
            {
                path: '/chat',
                meta: {
                    authRequires: true
                },
                name: 'chat',
                component: () => import('./pages/chat.vue')
            },
            {
                path: '/pushnotificationmaster',
                meta: {
                    authRequires: true
                },
                name: 'pushnotificationmaster',
                component: () => import('./pages/PushNotificationMaster.vue')
            },
            {
                path: '/role_master',
                meta: {
                    authRequires: true
                },
                name: 'role_master',
                component: () => import('./pages/role_master.vue')
            },
            {
                path: '/menu_master',
                meta: {
                    authRequires: true
                },
                name: 'menu_master',
                component: () => import('./pages/menu_master.vue')
            },
            {
                path: '/user_add',
                meta: {
                    authRequires: true
                },
                name: 'user_add',
                component: () => import('./pages/user_add.vue')
            },
            {
                path: '/user_edit',
                meta: {
                    authRequires: true
                },
                name: 'user_edit',
                component: () => import('./pages/user_edit.vue')
            },
            {
                path: '/client_add',
                meta: {
                    authRequires: true
                },
                name: 'client_add',
                component: () => import('./pages/client_add.vue')
            },
            {
                path: '/client_department',
                meta: {
                    authRequires: true
                },
                name: 'client_department',
                component: () => import('./pages/client_department.vue')
            },
            {
                path: '/DepartmentProfile',
                meta: {
                    authRequires: true
                },
                name: 'DepartmentProfile',
                component: () => import('./pages/DepartmentProfile.vue')
            },
            
            {
                path: '/clientProfile',
                name: 'clientProfile',
                component: () => import('./pages/ClientProfile.vue'),
               
            },
            {
                path: '/formlayout',
                meta: {
                    authRequires: true
                },
                name: 'formlayout',
                component: () => import('./components/FormLayoutDemo.vue')
            },
            {
                path: '/input',
                meta: {
                    authRequires: true
                },
                name: 'input',
                component: () => import('./components/InputDemo.vue')
            },
            {
                path: '/floatlabel',
                meta: {
                    authRequires: true
                },
                name: 'floatlabel',
                component: () => import('./components/FloatLabelDemo.vue')
            },
            {
                path: '/invalidstate',
                meta: {
                    authRequires: true
                },
                name: 'invalidstate',
                component: () => import('./components/InvalidStateDemo.vue')
            },
            {
                path: '/button',
                meta: {
                    authRequires: true
                },
                name: 'button',
                component: () => import('./components/ButtonDemo.vue')
            },
            {
                path: '/table',
                meta: {
                    authRequires: true
                },
                name: 'table',
                component: () => import('./components/TableDemo.vue'),
                
            },
            
            {
                path: '/table',
                meta: {
                    authRequires: true
                },
                name: 'table',
                component: () => import('./components/TableDemo.vue'),
                
            },
            
            
            {
                path: '/list',
                meta: {
                    authRequires: true
                },
                name: 'list',
                component: () => import('./components/ListDemo.vue')
            },
            {
                path: '/tree',
                meta: {
                    authRequires: true
                },
                name: 'tree',
                component: () => import('./components/TreeDemo.vue')
            },
            {
                path: '/panel',
                meta: {
                    authRequires: true
                },
                name: 'panel',
                component: () => import('./components/PanelsDemo.vue')
            },
            {
                path: '/overlay',
                meta: {
                    authRequires: true
                },
                name: 'overlay',
                component: () => import('./components/OverlayDemo.vue')
            },
            {
                path: '/media',
                meta: {
                    authRequires: true
                },
                name: 'media',
                component: () => import('./components/MediaDemo.vue')
            },
            {
                path: '/menu',
                component: () => import('./components/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                ],
            },
            {
                path: '/messages',
                meta: {
                    authRequires: true
                },
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            {
                path: '/file',
                meta: {
                    authRequires: true
                },
                name: 'file',
                component: () => import('./components/FileDemo.vue')
            },
            {
                path: '/chart',
                meta: {
                    authRequires: true
                },
                name: 'chart',
                component: () => import('./components/ChartDemo.vue')
            },
            {
                path: '/misc',
                meta: {
                    authRequires: true
                },
                name: 'misc',
                component: () => import('./components/MiscDemo.vue')
            },
            {
                path: '/crud',
                meta: {
                    authRequires: true
                },
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            
            
            {
                path: '/blocks',
                meta: {
                    authRequires: true
                },
                name: 'blocks',
                component: () => import('./components/BlocksDemo.vue')
            },
            {
                path: '/icons',
                meta: {
                    authRequires: true
                },
                name: 'icons',
                component: () => import('./components/IconsDemo.vue')
            },
            {
                path: '/tickets',
                meta: {
                    authRequires: true
                },
                name: 'tickets',
                component: () => import('./pages/Tickets.vue')
            },
            {
                path: '/categories',
                meta: {
                    authRequires: true
                },
                name: 'categories',
                component: () => import('./pages/Categories.vue')
            },
            {
                path: '/categoriesdetails',
                meta: {
                    authRequires: true
                },
                name: 'categoriesdetails',
                component: () => import('./pages/CategoriesDetails.vue')
            },
            {
                path: '/customerdetails',
                meta: {
                    authRequires: true
                },
                name: 'customerdetails',
                component: () => import('./pages/CustomerDetails.vue')
            },
            {
                path: '/agentdetails',
                meta: {
                    authRequires: true
                },
                name: 'agentdetails',
                component: () => import('./pages/AgentDetails.vue')
            },
            {
                path: '/customers',
                meta: {
                    authRequires: true
                },
                name: 'customers',
                component: () => import('./pages/Customers.vue')
            },
            {
                path: '/faqs',
                meta: {
                    authRequires: true
                },
                name: 'faqs',
                component: () => import('./pages/faqs.vue')
            },
            {
                path: '/ward',
                meta: {
                    authRequires: true
                },
                name: 'ward',
                component: () => import('./pages/ward.vue')
            },
            {
                path: '/Bill',
                meta: {
                    authRequires: true
                },
                name: 'Bill',
                component: () => import('./pages/Bill.vue')
            },
            {
                path: '/Settings',
                meta: {
                    authRequires: true
                },
                name: 'Settings',
                component: () => import('./pages/Settings.vue')
            },
            {
                path: '/agents',
                meta: {
                    authRequires: true
                },
                name: 'agents',
                component: () => import('./pages/Agents.vue')
            },
            {
                path: '/create_ticket',
                meta: {
                    authRequires: true
                },
                name: 'create_ticket',
                component: () => import('./pages/create_ticket.vue')
            },
            {
                path: '/CustomerEditTicket',
                meta: {
                    authRequires: true
                },
                name: 'CustomerEditTicket',
                component: () => import('./pages/CustomerEditTicket.vue')
            },
            {
                path: '/AdminEditTicket',
                meta: {
                    authRequires: true
                },
                name: 'AdminEditTicket',
                component: () => import('./pages/AdminEditTicket.vue')
            },
            {
                path: '/AgentEditTicket',
                meta: {
                    authRequires: true
                },
                name: 'AgentEditTicket',
                component: () => import('./pages/AgentEditTicket.vue')
            },
            {
                path: '/AgentAddEdit',
                meta: {
                    authRequires: true
                },
                name: 'AgentAddEdit',
                component: () => import('./pages/AgentAddEdit.vue')
            },
            {
                path: '/CustomerAddEdit',
                meta: {
                    authRequires: true
                },
                name: 'CustomerAddEdit',
                component: () => import('./pages/CustomerAddEdit.vue')
            },
            {
                path: '/ticket_details',
                meta: {
                    authRequires: true
                },
                name: 'ticket_details',
                component: () => import('./pages/ticket_details.vue')
            },
            {
                path: '/CustomerDashboard',
                meta: {
                    authRequires: true
                },
                name: 'CustomerDashboard',
                component: () => import('./pages/CustomerDashboard.vue')
            },
            {
                path: '/AdminDashboard',
                meta: {
                    authRequires: true
                },
                name: 'AdminDashboard',
                component: () => import('./pages/AdminDashboard.vue')
            },
            {
                path: '/AgentDashboard',
                meta: {
                    authRequires: true
                },
                name: 'AgentDashboard',
                component: () => import('./pages/AgentDashboard.vue')
            },
            {
                path: '/TypeMaster',
                meta: {
                    authRequires: true
                },
                name: 'Type',
                component: () => import('./pages/TypeMaster.vue')
            },
            {
                path: '/StatusMaster',
                meta: {
                    authRequires: true
                },
                name: 'Status',
                component: () => import('./pages/StatusMaster.vue')
            },
            
        ]
    },



    // {
    //     path: '/',
    //     name: 'login',
    //     component: () => import('./pages/Login.vue')
    // },
    {
        path: '/',
        name: 'Home',
        component: () => import('./pages/Home.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});


//var page_name = this.$route.name;
var user_name = localStorage.getItem("user_type");
var Menu_master = null;
var autoValue =null;
Menu_master = new Menu();


export default router;
